import React from 'react'

import SEO from '../components/Common/seo'
import AboutContainer from '../components/About/AboutContainer'

const About = () => (
  <>
  </>
)

export default About
